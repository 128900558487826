<template>
    <v-app-bar v-if="$auth.isAuthenticated"
      id="app-bar"
      absolute
      app
      color="transparent"
      flat
      height="75"
    >
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
      >
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>

        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>
      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.name"
      />

      <v-spacer />
      
      <div class="mx-3" />

      <div v-if="!$auth.loading">
      <button v-if="$auth.isAuthenticated" @click="logout">
      <v-btn
        class="ml-2"
        min-width="0"
        text
      >
      <v-icon>mdi-logout</v-icon>
      </v-btn>
      </button>
    </div>

    </v-app-bar>
</template>

<script>
  // Components

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
    
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      logout() {
        this.$auth.logout({
          returnTo: "https://www.bouldercontractingllc.com",
        });
        localStorage.clear()
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
  }
</script>
